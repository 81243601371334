<template>
  <div class="hide-scroll">
    <v-row>
      <v-col cols="4" class="pl-0">
        <v-autocomplete
          :items="clients"
          label="Selecteer een klant"
          item-text="name"
          item-value="id"
          hide-details
          solo
          v-model="selectedClient"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" v-if="selectedClient">
        <v-layout justify-center align-center fill-height>
          <p class="text-h5">
            Totaal punten:
            <span class="text-h4 font-weight-bold">{{
              clientProps.points
            }}</span>
          </p>
        </v-layout>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-card elevation="0" v-if="selectedClient" class="mt-4">
      <v-row>
        <v-col cols="4">Datum</v-col>
        <v-col cols="4">Type</v-col>
        <v-col cols="4">Points</v-col>
      </v-row>
      <v-card>
        <v-row class="px-4">
          <v-col cols="2">
            <v-menu min-width="auto">
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="pa-0"
                  hide-details
                  v-on="on"
                  :value="newItem.pickedDate"
                  outlined
                  dense
                  label="Datum"
                  slot="activator"
                ></v-text-field>
              </template>

              <v-date-picker v-model="newItem.pickedDate"></v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-select
              hide-details
              class="pa-0"
              :items="types"
              item-text="name"
              item-value="id"
              v-model="newItem.type"
              outlined
              dense
              label="Type"
            >
            </v-select>
          </v-col>
          <v-spacer></v-spacer>

          <v-col cols="2">
            <v-text-field
              class="pa-0"
              hide-details
              type="number"
              v-model="newItem.points"
              outlined
              dense
              label="Punten"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-layout justify-end align-center>
              <v-btn
                elevation="0"
                fab
                small
                color="primary"
                class="mr-4"
                :disabled="
                  newItem.points != undefined &&
                  newItem.type &&
                  newItem.pickedDate
                    ? false
                    : true
                "
                @click="addNewItem()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mt-4">
        <v-virtual-scroll
          :items="history"
          height="500"
          item-height="60"
          @scroll.native="handleScroll"
        >
          <template v-slot:default="{ item, index }">
            <v-list-item>
              <v-list-item-content>
                <v-row class="">
                  <v-col cols="2" class="pl-4 py-0">
                    <v-layout fill-height align-center>
                      {{ getFormattedDate(new Date(item.Date.$date)) }}
                    </v-layout>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" class="pl-6 py-0">
                    <v-layout fill-height align-center>
                      {{ types.find((el) => el.id == item.TypeId.$oid).name }}
                    </v-layout>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" class="pl-6 py-0">
                    <v-layout fill-height align-center>
                      {{ item.Points }}
                    </v-layout>
                  </v-col>
                  <v-col cols="2" class="pl-6 py-0">
                    <v-layout justify-end align-center>
                      <v-dialog v-model="dialog[index]" width="800">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="warning"
                            elevation="0"
                            icon
                            v-on="on"
                            v-show="itemIsEditable(item.TypeId.$oid)"
                            @click="prepEditItem(item)"
                          >
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title>
                            <span class="text-h5">Aanpassen</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="4">
                                  <v-menu min-width="auto">
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        class="pa-0"
                                        hide-details
                                        v-on="on"
                                        :value="editableDate"
                                        outlined
                                        dense
                                        label="Datum"
                                        slot="activator"
                                        required
                                      ></v-text-field>
                                    </template>

                                    <v-date-picker
                                      v-model="editableDate"
                                    ></v-date-picker>
                                  </v-menu>
                                </v-col>

                                <v-col cols="4">
                                  <v-select
                                    required
                                    hide-details
                                    class="pa-0"
                                    :items="types"
                                    item-text="name"
                                    item-value="id"
                                    v-model="item.TypeId.$oid"
                                    outlined
                                    dense
                                    label="Type"
                                  ></v-select>
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field
                                    class="pa-0"
                                    hide-details
                                    type="number"
                                    v-model="item.Points"
                                    outlined
                                    dense
                                    label="Punten"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary darken-1"
                              text
                              @click="dialog[index] = false"
                            >
                              Sluiten
                            </v-btn>
                            <v-btn
                              color="primary darken-1"
                              text
                              @click="editItem(index, item)"
                            >
                              Opslaan
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="deleteDialog[index]" width="800">
                        <template v-slot:activator="{ on }">
                          <v-btn color="error" elevation="0" icon v-on="on">
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Verwijderen
                          </v-card-title>

                          <v-card-text>
                            Ben je zeker dat je dit item wil verwijderen?
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="deleteDialog[index] = false"
                            >
                              Nee
                            </v-btn>
                            <v-btn
                              color="primary"
                              text
                              @click="deleteItem(index, item._id.$oid)"
                            >
                              Ja
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import request from "../../request";
export default {
  data() {
    return {
      dialog: {},
      deleteDialog: {},
      selectedClient: null,
      clientProps: null,
      types: [],
      startScroll: 0,
      countScroll: 10,
      newItem: {
        type: "",
        points: 0,
        pickedDate: null,
      },
      editableDate: null,
      clients: [],
      history: [],
      maxItems: 0,
    };
  },
  watch: {
    selectedClient: function (val) {
      request.get("/custom/total-points?customerId=" + val, null, (res) => {
        if (res.success) {
          this.$set(
            this.clients.find((el) => el.id == val),
            "points",
            res.data
          );
        }
      });
       request.get("/custom/points-count?customerId=" + val, null, (res) => {
        if (res.success) {
          this.maxItems=res.data
        }
      });
      this.startScroll = 0;
      this.history = [];
      this.getHistory(val);
      this.clientProps = this.clients.find((el) => el.id == val);
    },
    "newItem.type": function (val) {
      const type = this.types.find((item) => item.id === val);
      if (type) {
        this.newItem.points = type.value;
      }
    },
  },

  created() {
    request.get(
      "/pagecontentcount/618a4f962f1b33c8c020be53?start=0&sortBy=&sortDescending=false&search=&",
      null,
      (res) => {
        if (res.success) {
          for(var i = 0; i <= Math.ceil(res.data / 25); i++)
          {
            request.get(
              "/pagecontent/618a4f962f1b33c8c020be53?start="+(i*25)+"&sortBy=&sortDescending=false&search=&",
              null,
              (res) => {
                if (res.success) {
                  res.data.forEach((el) => {
                    this.types.push({
                      name: el.Data.Name.Value.Nl,
                      value: el.Data.Points.Value,
                      editable: el.Data.Editable.Value,
                      id: el._id.$oid,
                    });
                  });
                }
              }
            );
          }
        }
      }
    );

    request.get(
      "/pagecontentcount/618a4f962f1b33c8c020be51?start=0&sortBy=&sortDescending=false&search=&",
      null,
      (res) => {
        if (res.success) {
          for(var i = 0; i <= Math.ceil(res.data / 25); i++)
          {
            request.get(
              "/pagecontent/618a4f962f1b33c8c020be51?start="+(i*25)+"&sortBy=&sortDescending=false&search=&",
              null,
              (res) => {
                if (res.success) {
                  res.data.forEach((el) => {
                    this.clients.push({ name: el.Data.Name.Value.Nl, id: el._id.$oid });
                  });
                }
              }
            );
          }
        }
      }
    );
  },

  methods: {
    handleScroll(event) {
      const element = event.currentTarget || event.target;
      if (
        element &&
        element.scrollHeight - element.scrollTop === element.clientHeight &&
        this.maxItems > this.startScroll
      ) {
        this.startScroll += 10;
        this.getHistory(this.clientProps.id);
      }
    },
    getHistory(val) {
      request.get(
        "/custom/points?customerId=" +
          val +
          `&start=${this.startScroll}&count=${this.countScroll}`,
        null,
        (res) => {
          if (res.success) {
            res.data.forEach((element) => {
              this.$set(this.history, this.history.length, element);
            });
            return;
          }
        }
      );
    },
    totalPoints() {
      request.get(
        "/custom/total-points?customerId=" + this.clientProps.id,
        null,
        (res) => {
          if (res.success) {
            this.clients.find((el) => el.id == this.clientProps.id)["points"] =
              res.data;
            this.clientProps = this.clients.find(
              (el) => el.id == this.clientProps.id
            );
            return;
          }
        }
      );
    },
    addNewItem() {
      if (
        this.newItem.pickedDate &&
        this.newItem.type &&
        this.newItem.points != undefined
      ) {
        request.put(
          "/custom/points",
          {
            CustomerId: this.clientProps.id,
            TypeId: this.newItem.type,
            Points: parseInt(this.newItem.points),
            Date: this.newItem.pickedDate,
          },
          (res) => {
            if (res.success) {
              this.totalPoints();
              this.startScroll = 0;
              this.history = [];
              this.getHistory(this.clientProps.id);
            }
          }
        );
      }
      this.newItem = {
        type: "",
        points: 0,
        pickedDate: null,
      };
    },
    prepEditItem(item) {
      request.get(
        "/custom/points-by-id?pointsId=" + item._id.$oid,
        null,
        (res) => {
          if (res.success) {
            this.editableDate = new Date(res.data.Date.$date)
              .toISOString()
              .substr(0, 10);
          }
        }
      );
    },
    editItem(index, item) {
      request.put(
        "/custom/points",
        {
          _id: item._id.$oid,
          CustomerId: item.CustomerId.$oid,
          TypeId: item.TypeId.$oid,
          Points: item.Points,
          Date: this.editableDate,
        },
        (res) => {
          if (res.success) {
            this.dialog[index] = false;
            this.totalPoints();
            this.startScroll = 0;
            this.history = [];
            this.getHistory(this.clientProps.id);
          }
        }
      );
    },
    changeDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    },
    findUser() {
      return this.clients.find((el) => el.id == this.clientProps.id);
    },
    deleteItem(index, id) {
      request.delete("/custom/points?pointsId=" + id, null, (res) => {
        if (res.success) {
          this.deleteDialog[index] = false;
          this.totalPoints();
          this.startScroll = 0;
          this.history = [];
          this.getHistory(this.clientProps.id);
        }
      });
    },
    getFormattedDate(date) {
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");

      return day + "/" + month + "/" + year;
    },
    itemIsEditable(oid) {
      return this.types.find((el) => el.id == oid).editable;
    },
  },
};
</script>

<style>
</style>