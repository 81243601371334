var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hide-scroll"},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.clients,"label":"Selecteer een klant","item-text":"name","item-value":"id","hide-details":"","solo":""},model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}})],1),(_vm.selectedClient)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-layout',{attrs:{"justify-center":"","align-center":"","fill-height":""}},[_c('p',{staticClass:"text-h5"},[_vm._v(" Totaal punten: "),_c('span',{staticClass:"text-h4 font-weight-bold"},[_vm._v(_vm._s(_vm.clientProps.points))])])])],1):_vm._e(),_c('v-spacer')],1),(_vm.selectedClient)?_c('v-card',{staticClass:"mt-4",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Datum")]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Type")]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Points")])],1),_c('v-card',[_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"pa-0",attrs:{"slot":"activator","hide-details":"","value":_vm.newItem.pickedDate,"outlined":"","dense":"","label":"Datum"},slot:"activator"},on))]}}],null,false,184265514)},[_c('v-date-picker',{model:{value:(_vm.newItem.pickedDate),callback:function ($$v) {_vm.$set(_vm.newItem, "pickedDate", $$v)},expression:"newItem.pickedDate"}})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"pa-0",attrs:{"hide-details":"","items":_vm.types,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Type"},model:{value:(_vm.newItem.type),callback:function ($$v) {_vm.$set(_vm.newItem, "type", $$v)},expression:"newItem.type"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"pa-0",attrs:{"hide-details":"","type":"number","outlined":"","dense":"","label":"Punten"},model:{value:(_vm.newItem.points),callback:function ($$v) {_vm.$set(_vm.newItem, "points", $$v)},expression:"newItem.points"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-layout',{attrs:{"justify-end":"","align-center":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"elevation":"0","fab":"","small":"","color":"primary","disabled":_vm.newItem.points != undefined &&
                _vm.newItem.type &&
                _vm.newItem.pickedDate
                  ? false
                  : true},on:{"click":function($event){return _vm.addNewItem()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-virtual-scroll',{attrs:{"items":_vm.history,"height":"500","item-height":"60"},nativeOn:{"scroll":function($event){return _vm.handleScroll.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-row',{},[_c('v-col',{staticClass:"pl-4 py-0",attrs:{"cols":"2"}},[_c('v-layout',{attrs:{"fill-height":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getFormattedDate(new Date(item.Date.$date)))+" ")])],1),_c('v-spacer'),_c('v-col',{staticClass:"pl-6 py-0",attrs:{"cols":"2"}},[_c('v-layout',{attrs:{"fill-height":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.types.find(function (el) { return el.id == item.TypeId.$oid; }).name)+" ")])],1),_c('v-spacer'),_c('v-col',{staticClass:"pl-6 py-0",attrs:{"cols":"2"}},[_c('v-layout',{attrs:{"fill-height":"","align-center":""}},[_vm._v(" "+_vm._s(item.Points)+" ")])],1),_c('v-col',{staticClass:"pl-6 py-0",attrs:{"cols":"2"}},[_c('v-layout',{attrs:{"justify-end":"","align-center":""}},[_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.itemIsEditable(item.TypeId.$oid)),expression:"itemIsEditable(item.TypeId.$oid)"}],attrs:{"color":"warning","elevation":"0","icon":""},on:{"click":function($event){return _vm.prepEditItem(item)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true),model:{value:(_vm.dialog[index]),callback:function ($$v) {_vm.$set(_vm.dialog, index, $$v)},expression:"dialog[index]"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Aanpassen")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"pa-0",attrs:{"slot":"activator","hide-details":"","value":_vm.editableDate,"outlined":"","dense":"","label":"Datum","required":""},slot:"activator"},on))]}}],null,true)},[_c('v-date-picker',{model:{value:(_vm.editableDate),callback:function ($$v) {_vm.editableDate=$$v},expression:"editableDate"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"pa-0",attrs:{"required":"","hide-details":"","items":_vm.types,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Type"},model:{value:(item.TypeId.$oid),callback:function ($$v) {_vm.$set(item.TypeId, "$oid", $$v)},expression:"item.TypeId.$oid"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pa-0",attrs:{"hide-details":"","type":"number","outlined":"","dense":"","label":"Punten","required":""},model:{value:(item.Points),callback:function ($$v) {_vm.$set(item, "Points", $$v)},expression:"item.Points"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.dialog[index] = false}}},[_vm._v(" Sluiten ")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.editItem(index, item)}}},[_vm._v(" Opslaan ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","elevation":"0","icon":""}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true),model:{value:(_vm.deleteDialog[index]),callback:function ($$v) {_vm.$set(_vm.deleteDialog, index, $$v)},expression:"deleteDialog[index]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Verwijderen ")]),_c('v-card-text',[_vm._v(" Ben je zeker dat je dit item wil verwijderen? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteDialog[index] = false}}},[_vm._v(" Nee ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.deleteItem(index, item._id.$oid)}}},[_vm._v(" Ja ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-divider')]}}],null,false,3468232876)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }